const df_355_1 = new (require('@comunica/logger-void').LoggerVoid)();
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_init__2_0_0_components_ActorInit_jsonld_ActorInit_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-init/^2.0.0/components/ActorInit.jsonld#ActorInit_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_context_preprocess__2_0_0_components_ActorContextPreprocess_jsonld_ActorContextPreprocess_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-context-preprocess/^2.0.0/components/ActorContextPreprocess.jsonld#ActorContextPreprocess_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_hash_bindings__2_0_0_components_ActorHashBindings_jsonld_ActorHashBindings_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-hash-bindings/^2.0.0/components/ActorHashBindings.jsonld#ActorHashBindings_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_optimize_query_operation__2_0_0_components_ActorOptimizeQueryOperation_jsonld_ActorOptimizeQueryOperation_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-optimize-query-operation/^2.0.0/components/ActorOptimizeQueryOperation.jsonld#ActorOptimizeQueryOperation_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus = new (require('@comunica/bus-query-operation').BusQueryOperation)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-query-operation/^2.0.0/components/ActorQueryOperation.jsonld#ActorQueryOperation_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_parse__2_0_0_components_ActorQueryParse_jsonld_ActorQueryParse_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-query-parse/^2.0.0/components/ActorQueryParse.jsonld#ActorQueryParse_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-query-result-serialize/^2.0.0/components/ActorQueryResultSerialize.jsonld#ActorQueryResultSerialize_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_http_invalidate__2_0_0_components_ActorHttpInvalidate_jsonld_ActorHttpInvalidate_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-http-invalidate/^2.0.0/components/ActorHttpInvalidate.jsonld#ActorHttpInvalidate_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_serialize__2_0_0_components_ActorRdfSerialize_jsonld_ActorRdfSerialize_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-rdf-serialize/^2.0.0/components/ActorRdfSerialize.jsonld#ActorRdfSerialize_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_http__2_0_0_components_ActorHttp_jsonld_ActorHttp_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-http/^2.0.0/components/ActorHttp.jsonld#ActorHttp_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join_entries_sort__2_0_0_components_ActorRdfJoinEntriesSort_jsonld_ActorRdfJoinEntriesSort_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-rdf-join-entries-sort/^2.0.0/components/ActorRdfJoinEntriesSort.jsonld#ActorRdfJoinEntriesSort_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join_selectivity__2_0_0_components_ActorRdfJoinSelectivity_jsonld_ActorRdfJoinSelectivity_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-rdf-join-selectivity/^2.0.0/components/ActorRdfJoinSelectivity.jsonld#ActorRdfJoinSelectivity_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_update_quads__2_0_0_components_ActorRdfUpdateQuads_jsonld_ActorRdfUpdateQuads_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-rdf-update-quads/^2.0.0/components/ActorRdfUpdateQuads.jsonld#ActorRdfUpdateQuads_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-rdf-join/^2.0.0/components/ActorRdfJoin.jsonld#ActorRdfJoin_default_bus'
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_resolve_quad_pattern__2_0_0_components_ActorRdfResolveQuadPattern_jsonld_ActorRdfResolveQuadPattern_default_bus = new (require('@comunica/core').Bus)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/bus-rdf-resolve-quad-pattern/^2.0.0/components/ActorRdfResolveQuadPattern.jsonld#ActorRdfResolveQuadPattern_default_bus'
});
const urn_comunica_default_context_preprocess_actors_source_to_destination = new (require('@comunica/actor-context-preprocess-source-to-destination').ActorContextPreprocessSourceToDestination)({
  'name': 'urn:comunica:default:context-preprocess/actors#source-to-destination',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_context_preprocess__2_0_0_components_ActorContextPreprocess_jsonld_ActorContextPreprocess_default_bus
});
const urn_comunica_default_context_preprocess_mediators_main = new (require('@comunica/mediator-combine-pipeline').MediatorCombinePipeline)({
  'name': 'urn:comunica:default:context-preprocess/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_context_preprocess__2_0_0_components_ActorContextPreprocess_jsonld_ActorContextPreprocess_default_bus
});
const urn_comunica_default_hash_bindings_actors_sha1 = new (require('@comunica/actor-hash-bindings-sha1').ActorHashBindingsSha1)({
  'name': 'urn:comunica:default:hash-bindings/actors#sha1',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_hash_bindings__2_0_0_components_ActorHashBindings_jsonld_ActorHashBindings_default_bus
});
const urn_comunica_default_hash_bindings_mediators_main = new (require('@comunica/mediator-race').MediatorRace)({
  'name': 'urn:comunica:default:hash-bindings/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_hash_bindings__2_0_0_components_ActorHashBindings_jsonld_ActorHashBindings_default_bus
});
const urn_comunica_default_optimize_query_operation_mediators_main = new (require('@comunica/mediator-combine-pipeline').MediatorCombinePipeline)({
  'name': 'urn:comunica:default:optimize-query-operation/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_optimize_query_operation__2_0_0_components_ActorOptimizeQueryOperation_jsonld_ActorOptimizeQueryOperation_default_bus
});
const urn_comunica_default_optimize_query_operation_actors_join_bgp = new (require('@comunica/actor-optimize-query-operation-join-bgp').ActorOptimizeQueryOperationJoinBgp)({
  'name': 'urn:comunica:default:optimize-query-operation/actors#join-bgp',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_optimize_query_operation__2_0_0_components_ActorOptimizeQueryOperation_jsonld_ActorOptimizeQueryOperation_default_bus
});
const urn_comunica_default_optimize_query_operation_actors_bgp_to_join = new (require('@comunica/actor-optimize-query-operation-bgp-to-join').ActorOptimizeQueryOperationBgpToJoin)({
  'name': 'urn:comunica:default:optimize-query-operation/actors#bgp-to-join',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_optimize_query_operation__2_0_0_components_ActorOptimizeQueryOperation_jsonld_ActorOptimizeQueryOperation_default_bus
});
const urn_comunica_default_optimize_query_operation_actors_join_connected = new (require('@comunica/actor-optimize-query-operation-join-connected').ActorOptimizeQueryOperationJoinConnected)({
  'name': 'urn:comunica:default:optimize-query-operation/actors#join-connected',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_optimize_query_operation__2_0_0_components_ActorOptimizeQueryOperation_jsonld_ActorOptimizeQueryOperation_default_bus
});
const urn_comunica_default_query_operation_mediators_main = new (require('@comunica/mediator-number').MediatorNumber)({
  'field': 'httpRequests',
  'type': 'min',
  'ignoreErrors': true,
  'name': 'urn:comunica:default:query-operation/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_values = new (require('@comunica/actor-query-operation-values').ActorQueryOperationValues)({
  'name': 'urn:comunica:default:query-operation/actors#values',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_parse_mediators_main = new (require('@comunica/mediator-race').MediatorRace)({
  'name': 'urn:comunica:default:query-parse/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_parse__2_0_0_components_ActorQueryParse_jsonld_ActorQueryParse_default_bus
});
const urn_comunica_default_query_parse_actors_sparql = new (require('@comunica/actor-query-parse-sparql').ActorQueryParseSparql)({
  'prefixes': {"dbpedia":"http://dbpedia.org/resource/","dbpedia-owl":"http://dbpedia.org/ontology/","dbpprop":"http://dbpedia.org/property/","dc":"http://purl.org/dc/terms/","dc11":"http://purl.org/dc/elements/1.1/","dcterms":"http://purl.org/dc/terms/","foaf":"http://xmlns.com/foaf/0.1/","geo":"http://www.w3.org/2003/01/geo/wgs84_pos#","owl":"http://www.w3.org/2002/07/owl#","rdf":"http://www.w3.org/1999/02/22-rdf-syntax-ns#","rdfs":"http://www.w3.org/2000/01/rdf-schema#","schema":"http://schema.org/","skos":"http://www.w3.org/2008/05/skos#","xsd":"http://www.w3.org/2001/XMLSchema#"},
  'name': 'urn:comunica:default:query-parse/actors#sparql',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_parse__2_0_0_components_ActorQueryParse_jsonld_ActorQueryParse_default_bus
});
const urn_comunica_default_query_parse_actors_graphql = new (require('@comunica/actor-query-parse-graphql').ActorQueryParseGraphql)({
  'name': 'urn:comunica:default:query-parse/actors#graphql',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_parse__2_0_0_components_ActorQueryParse_jsonld_ActorQueryParse_default_bus
});
const urn_comunica_default_query_result_serialize_mediators_serialize = new (require('@comunica/mediator-race').MediatorRace)({
  'name': 'urn:comunica:default:query-result-serialize/mediators#serialize',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_mediators_mediaType = new (require('@comunica/mediator-combine-union').MediatorCombineUnion)({
  'field': 'mediaTypes',
  'name': 'urn:comunica:default:query-result-serialize/mediators#mediaType',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_mediators_mediaTypeFormat = new (require('@comunica/mediator-combine-union').MediatorCombineUnion)({
  'field': 'mediaTypeFormats',
  'name': 'urn:comunica:default:query-result-serialize/mediators#mediaTypeFormat',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_json = new (require('@comunica/actor-query-result-serialize-json').ActorQueryResultSerializeJson)({
  'mediaTypePriorities': {"application/json":1},
  'mediaTypeFormats': {"application/json":"https://comunica.linkeddatafragments.org/#results_JSON"},
  'name': 'urn:comunica:default:query-result-serialize/actors#json',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_simple = new (require('@comunica/actor-query-result-serialize-simple').ActorQueryResultSerializeSimple)({
  'mediaTypePriorities': {"simple":0.9},
  'mediaTypeFormats': {"simple":"https://comunica.linkeddatafragments.org/#results_simple"},
  'name': 'urn:comunica:default:query-result-serialize/actors#simple',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_csv = new (require('@comunica/actor-query-result-serialize-sparql-csv').ActorQueryResultSerializeSparqlCsv)({
  'mediaTypePriorities': {"text/csv":0.75},
  'mediaTypeFormats': {"text/csv":"http://www.w3.org/ns/formats/SPARQL_Results_CSV"},
  'name': 'urn:comunica:default:query-result-serialize/actors#csv',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_sparql_json = new (require('@comunica/actor-query-result-serialize-sparql-json').ActorQueryResultSerializeSparqlJson)({
  'mediaTypePriorities': {"application/sparql-results+json":0.8},
  'mediaTypeFormats': {"application/sparql-results+json":"http://www.w3.org/ns/formats/SPARQL_Results_JSON"},
  'name': 'urn:comunica:default:query-result-serialize/actors#sparql-json',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_sparql_tsv = new (require('@comunica/actor-query-result-serialize-sparql-tsv').ActorQueryResultSerializeSparqlTsv)({
  'mediaTypePriorities': {"text/tab-separated-values":0.75},
  'mediaTypeFormats': {"text/tab-separated-values":"http://www.w3.org/ns/formats/SPARQL_Results_TSV"},
  'name': 'urn:comunica:default:query-result-serialize/actors#sparql-tsv',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_sparql_xml = new (require('@comunica/actor-query-result-serialize-sparql-xml').ActorQueryResultSerializeSparqlXml)({
  'mediaTypePriorities': {"application/sparql-results+xml":0.8},
  'mediaTypeFormats': {"application/sparql-results+xml":"http://www.w3.org/ns/formats/SPARQL_Results_XML"},
  'name': 'urn:comunica:default:query-result-serialize/actors#sparql-xml',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_table = new (require('@comunica/actor-query-result-serialize-table').ActorQueryResultSerializeTable)({
  'columnWidth': 50,
  'mediaTypePriorities': {"table":0.6},
  'mediaTypeFormats': {"table":"https://comunica.linkeddatafragments.org/#results_table"},
  'name': 'urn:comunica:default:query-result-serialize/actors#table',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_tree = new (require('@comunica/actor-query-result-serialize-tree').ActorQueryResultSerializeTree)({
  'mediaTypePriorities': {"tree":0.5},
  'mediaTypeFormats': {"tree":"https://comunica.linkeddatafragments.org/#results_tree"},
  'name': 'urn:comunica:default:query-result-serialize/actors#tree',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_http_invalidate_mediators_main = new (require('@comunica/mediator-all').MediatorAll)({
  'name': 'urn:comunica:default:http-invalidate/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_http_invalidate__2_0_0_components_ActorHttpInvalidate_jsonld_ActorHttpInvalidate_default_bus
});
const urn_comunica_default_rdf_serialize_mediators_serialize = new (require('@comunica/mediator-race').MediatorRace)({
  'name': 'urn:comunica:default:rdf-serialize/mediators#serialize',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_serialize__2_0_0_components_ActorRdfSerialize_jsonld_ActorRdfSerialize_default_bus
});
const urn_comunica_default_rdf_serialize_mediators_mediaType = new (require('@comunica/mediator-combine-union').MediatorCombineUnion)({
  'field': 'mediaTypes',
  'name': 'urn:comunica:default:rdf-serialize/mediators#mediaType',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_serialize__2_0_0_components_ActorRdfSerialize_jsonld_ActorRdfSerialize_default_bus
});
const urn_comunica_default_rdf_serialize_mediators_mediaTypeFormat = new (require('@comunica/mediator-combine-union').MediatorCombineUnion)({
  'field': 'mediaTypeFormats',
  'name': 'urn:comunica:default:rdf-serialize/mediators#mediaTypeFormat',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_serialize__2_0_0_components_ActorRdfSerialize_jsonld_ActorRdfSerialize_default_bus
});
const urn_comunica_default_rdf_serialize_actors_n3 = new (require('@comunica/actor-rdf-serialize-n3').ActorRdfSerializeN3)({
  'mediaTypePriorities': {"application/n-quads":1,"application/n-triples":0.8,"application/trig":0.95,"text/n3":0.35,"text/turtle":0.6},
  'mediaTypeFormats': {"application/n-quads":"http://www.w3.org/ns/formats/N-Quads","application/n-triples":"http://www.w3.org/ns/formats/N-Triples","application/trig":"http://www.w3.org/ns/formats/TriG","text/n3":"http://www.w3.org/ns/formats/N3","text/turtle":"http://www.w3.org/ns/formats/Turtle"},
  'name': 'urn:comunica:default:rdf-serialize/actors#n3',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_serialize__2_0_0_components_ActorRdfSerialize_jsonld_ActorRdfSerialize_default_bus
});
const urn_comunica_default_rdf_serialize_actors_jsonld = new (require('@comunica/actor-rdf-serialize-jsonld').ActorRdfSerializeJsonLd)({
  'jsonStringifyIndentSpaces': 2,
  'mediaTypePriorities': {"application/ld+json":1},
  'mediaTypeFormats': {"application/ld+json":"http://www.w3.org/ns/formats/JSON-LD"},
  'priorityScale': 0.9,
  'name': 'urn:comunica:default:rdf-serialize/actors#jsonld',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_serialize__2_0_0_components_ActorRdfSerialize_jsonld_ActorRdfSerialize_default_bus
});
const https___linkedsoftwaredependencies_org_bundles_npm__comunica_actor_query_result_serialize_stats__2_0_0_components_ActorQueryResultSerializeStats_jsonld_ActorQueryResultSerializeStats_default_observer = new (require('@comunica/actor-query-result-serialize-stats').ActionObserverHttp)({
  'name': 'https://linkedsoftwaredependencies.org/bundles/npm/@comunica/actor-query-result-serialize-stats/^2.0.0/components/ActorQueryResultSerializeStats.jsonld#ActorQueryResultSerializeStats_default_observer',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_http__2_0_0_components_ActorHttp_jsonld_ActorHttp_default_bus
});
const urn_comunica_default_http_mediators_main = new (require('@comunica/mediator-number').MediatorNumber)({
  'field': 'time',
  'type': 'min',
  'ignoreErrors': true,
  'name': 'urn:comunica:default:http/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_http__2_0_0_components_ActorHttp_jsonld_ActorHttp_default_bus
});
const urn_comunica_default_rdf_join_entries_sort_actors_cardinality = new (require('@comunica/actor-rdf-join-entries-sort-cardinality').ActorRdfJoinEntriesSortCardinality)({
  'name': 'urn:comunica:default:rdf-join-entries-sort/actors#cardinality',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join_entries_sort__2_0_0_components_ActorRdfJoinEntriesSort_jsonld_ActorRdfJoinEntriesSort_default_bus
});
const urn_comunica_default_rdf_join_entries_sort_mediators_main = new (require('@comunica/mediator-race').MediatorRace)({
  'name': 'urn:comunica:default:rdf-join-entries-sort/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join_entries_sort__2_0_0_components_ActorRdfJoinEntriesSort_jsonld_ActorRdfJoinEntriesSort_default_bus
});
const urn_comunica_default_rdf_join_selectivity_actors_variable_counting = new (require('@comunica/actor-rdf-join-selectivity-variable-counting').ActorRdfJoinSelectivityVariableCounting)({
  'name': 'urn:comunica:default:rdf-join-selectivity/actors#variable-counting',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join_selectivity__2_0_0_components_ActorRdfJoinSelectivity_jsonld_ActorRdfJoinSelectivity_default_bus
});
const urn_comunica_default_rdf_join_selectivity_mediators_main = new (require('@comunica/mediator-number').MediatorNumber)({
  'field': 'accuracy',
  'type': 'max',
  'ignoreErrors': true,
  'name': 'urn:comunica:default:rdf-join-selectivity/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join_selectivity__2_0_0_components_ActorRdfJoinSelectivity_jsonld_ActorRdfJoinSelectivity_default_bus
});
const urn_comunica_default_rdf_update_quads_actors_rdfjs_store = new (require('@comunica/actor-rdf-update-quads-rdfjs-store').ActorRdfUpdateQuadsRdfJsStore)({
  'name': 'urn:comunica:default:rdf-update-quads/actors#rdfjs-store',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_update_quads__2_0_0_components_ActorRdfUpdateQuads_jsonld_ActorRdfUpdateQuads_default_bus
});
const urn_comunica_default_rdf_update_quads_mediators_main = new (require('@comunica/mediator-race').MediatorRace)({
  'name': 'urn:comunica:default:rdf-update-quads/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_update_quads__2_0_0_components_ActorRdfUpdateQuads_jsonld_ActorRdfUpdateQuads_default_bus
});
const urn_comunica_default_rdf_join_mediators_main = new (require('@comunica/mediator-join-coefficients-fixed').MediatorJoinCoefficientsFixed)({
  'cpuWeight': 1,
  'memoryWeight': 1,
  'timeWeight': 10,
  'ioWeight': 100,
  'name': 'urn:comunica:default:rdf-join/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_resolve_quad_pattern_mediators_main = new (require('@comunica/mediator-race').MediatorRace)({
  'name': 'urn:comunica:default:rdf-resolve-quad-pattern/mediators#main',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_resolve_quad_pattern__2_0_0_components_ActorRdfResolveQuadPattern_jsonld_ActorRdfResolveQuadPattern_default_bus
});
const urn_comunica_default_rdf_resolve_quad_pattern_actors_rdfjs_source = new (require('@comunica/actor-rdf-resolve-quad-pattern-rdfjs-source').ActorRdfResolveQuadPatternRdfJsSource)({
  'name': 'urn:comunica:default:rdf-resolve-quad-pattern/actors#rdfjs-source',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_resolve_quad_pattern__2_0_0_components_ActorRdfResolveQuadPattern_jsonld_ActorRdfResolveQuadPattern_default_bus
});
const urn_comunica_default_query_operation_actors_ask = new (require('@comunica/actor-query-operation-ask').ActorQueryOperationAsk)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#ask',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_bgp = new (require('@comunica/actor-query-operation-bgp-join').ActorQueryOperationBgpJoin)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#bgp',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_construct = new (require('@comunica/actor-query-operation-construct').ActorQueryOperationConstruct)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#construct',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_describe = new (require('@comunica/actor-query-operation-describe-subject').ActorQueryOperationDescribeSubject)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#describe',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_distinct = new (require('@comunica/actor-query-operation-distinct-hash').ActorQueryOperationDistinctHash)({
  'mediatorHashBindings': urn_comunica_default_hash_bindings_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#distinct',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_extend = new (require('@comunica/actor-query-operation-extend').ActorQueryOperationExtend)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#extend',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_filter = new (require('@comunica/actor-query-operation-filter-sparqlee').ActorQueryOperationFilterSparqlee)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#filter',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_from = new (require('@comunica/actor-query-operation-from-quad').ActorQueryOperationFromQuad)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#from',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_group = new (require('@comunica/actor-query-operation-group').ActorQueryOperationGroup)({
  'mediatorHashBindings': urn_comunica_default_hash_bindings_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#group',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_nop = new (require('@comunica/actor-query-operation-nop').ActorQueryOperationNop)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#nop',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_orderby = new (require('@comunica/actor-query-operation-orderby-sparqlee').ActorQueryOperationOrderBySparqlee)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#orderby',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_project = new (require('@comunica/actor-query-operation-project').ActorQueryOperationProject)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#project',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_reduced = new (require('@comunica/actor-query-operation-reduced-hash').ActorQueryOperationReducedHash)({
  'mediatorHashBindings': urn_comunica_default_hash_bindings_mediators_main,
  'cacheSize': 100,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#reduced',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_service = new (require('@comunica/actor-query-operation-service').ActorQueryOperationService)({
  'forceSparqlEndpoint': false,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#service',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_slice = new (require('@comunica/actor-query-operation-slice').ActorQueryOperationSlice)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#slice',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_union = new (require('@comunica/actor-query-operation-union').ActorQueryOperationUnion)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#union',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_path_alt = new (require('@comunica/actor-query-operation-path-alt').ActorQueryOperationPathAlt)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#path-alt',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_path_inv = new (require('@comunica/actor-query-operation-path-inv').ActorQueryOperationPathInv)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#path-inv',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_path_link = new (require('@comunica/actor-query-operation-path-link').ActorQueryOperationPathLink)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#path-link',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_path_nps = new (require('@comunica/actor-query-operation-path-nps').ActorQueryOperationPathNps)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#path-nps',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_path_one_or_more = new (require('@comunica/actor-query-operation-path-one-or-more').ActorQueryOperationPathOneOrMore)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#path-one-or-more',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_path_zero_or_more = new (require('@comunica/actor-query-operation-path-zero-or-more').ActorQueryOperationPathZeroOrMore)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#path-zero-or-more',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_path_zero_or_one = new (require('@comunica/actor-query-operation-path-zero-or-one').ActorQueryOperationPathZeroOrOne)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#path-zero-or-one',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_update_add = new (require('@comunica/actor-query-operation-update-add-rewrite').ActorQueryOperationAddRewrite)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-add',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_update_composite = new (require('@comunica/actor-query-operation-update-compositeupdate').ActorQueryOperationUpdateCompositeUpdate)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-composite',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_update_copy = new (require('@comunica/actor-query-operation-update-copy-rewrite').ActorQueryOperationCopyRewrite)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-copy',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_update_move = new (require('@comunica/actor-query-operation-update-move-rewrite').ActorQueryOperationMoveRewrite)({
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-move',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_init_actors_query = new (require('@comunica/actor-init-query').ActorInitQuery)({
  'mediatorOptimizeQueryOperation': urn_comunica_default_optimize_query_operation_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'mediatorQueryParse': urn_comunica_default_query_parse_mediators_main,
  'mediatorQueryResultSerialize': urn_comunica_default_query_result_serialize_mediators_serialize,
  'mediatorQueryResultSerializeMediaTypeCombiner': urn_comunica_default_query_result_serialize_mediators_mediaType,
  'mediatorQueryResultSerializeMediaTypeFormatCombiner': urn_comunica_default_query_result_serialize_mediators_mediaTypeFormat,
  'mediatorContextPreprocess': urn_comunica_default_context_preprocess_mediators_main,
  'mediatorHttpInvalidate': urn_comunica_default_http_invalidate_mediators_main,
  'logger': df_355_1,
  'defaultQueryInputFormat': 'sparql',
  'contextKeyShortcuts': {"baseIRI":"@comunica/actor-init-query:baseIRI","datetime":"@comunica/actor-http-memento:datetime","destination":"@comunica/bus-rdf-update-quads:destination","explain":"@comunica/actor-init-query:explain","extensionFunctionCreator":"@comunica/actor-init-query:extensionFunctionCreator","extensionFunctions":"@comunica/actor-init-query:extensionFunctions","fetch":"@comunica/bus-http:fetch","httpAuth":"@comunica/bus-http:auth","httpIncludeCredentials":"@comunica/bus-http:include-credentials","httpProxyHandler":"@comunica/actor-http-proxy:httpProxyHandler","initialBindings":"@comunica/actor-init-query:initialBindings","lenient":"@comunica/actor-init-query:lenient","log":"@comunica/core:log","queryFormat":"@comunica/actor-init-query:queryFormat","queryTimestamp":"@comunica/actor-init-query:queryTimestamp","readOnly":"@comunica/bus-query-operation:readOnly","source":"@comunica/bus-rdf-resolve-quad-pattern:source","sources":"@comunica/bus-rdf-resolve-quad-pattern:sources"},
  'name': 'urn:comunica:default:init/actors#query',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_init__2_0_0_components_ActorInit_jsonld_ActorInit_default_bus
});
const urn_comunica_default_query_result_serialize_actors_rdf = new (require('@comunica/actor-query-result-serialize-rdf').ActorQueryResultSerializeRdf)({
  'mediatorRdfSerialize': urn_comunica_default_rdf_serialize_mediators_serialize,
  'mediatorMediaTypeCombiner': urn_comunica_default_rdf_serialize_mediators_mediaType,
  'mediatorMediaTypeFormatCombiner': urn_comunica_default_rdf_serialize_mediators_mediaTypeFormat,
  'name': 'urn:comunica:default:query-result-serialize/actors#rdf',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_result_serialize_actors_stats = new (require('@comunica/actor-query-result-serialize-stats').ActorQueryResultSerializeStats)({
  'httpObserver': https___linkedsoftwaredependencies_org_bundles_npm__comunica_actor_query_result_serialize_stats__2_0_0_components_ActorQueryResultSerializeStats_jsonld_ActorQueryResultSerializeStats_default_observer,
  'mediaTypePriorities': {"stats":0.5},
  'mediaTypeFormats': {"stats":"https://comunica.linkeddatafragments.org/#results_stats"},
  'name': 'urn:comunica:default:query-result-serialize/actors#stats',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_result_serialize__2_0_0_components_ActorQueryResultSerialize_jsonld_ActorQueryResultSerialize_default_bus
});
const urn_comunica_default_query_operation_actors_sparql_endpoint = new (require('@comunica/actor-query-operation-sparql-endpoint').ActorQueryOperationSparqlEndpoint)({
  'mediatorHttp': urn_comunica_default_http_mediators_main,
  'checkUrlSuffixSparql': true,
  'checkUrlSuffixUpdate': true,
  'forceHttpGet': false,
  'name': 'urn:comunica:default:query-operation/actors#sparql-endpoint',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_rdf_join_actors_inner_none = new (require('@comunica/actor-rdf-join-inner-none').ActorRdfJoinNone)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#inner-none',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_inner_single = new (require('@comunica/actor-rdf-join-inner-single').ActorRdfJoinSingle)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#inner-single',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_inner_multi_empty = new (require('@comunica/actor-rdf-join-inner-multi-empty').ActorRdfJoinMultiEmpty)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#inner-multi-empty',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_inner_multi_bind = new (require('@comunica/actor-rdf-join-inner-multi-bind').ActorRdfJoinMultiBind)({
  'bindOrder': 'depth-first',
  'selectivityModifier': 0.0001,
  'mediatorJoinEntriesSort': urn_comunica_default_rdf_join_entries_sort_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#inner-multi-bind',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_inner_hash = new (require('@comunica/actor-rdf-join-inner-hash').ActorRdfJoinHash)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#inner-hash',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_inner_symmetric_hash = new (require('@comunica/actor-rdf-join-inner-symmetrichash').ActorRdfJoinSymmetricHash)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#inner-symmetric-hash',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_inner_nested_loop = new (require('@comunica/actor-rdf-join-inner-nestedloop').ActorRdfJoinNestedLoop)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#inner-nested-loop',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_minus_hash = new (require('@comunica/actor-rdf-join-minus-hash').ActorRdfJoinMinusHash)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#minus-hash',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_minus_hash_undef = new (require('@comunica/actor-rdf-join-minus-hash-undef').ActorRdfJoinMinusHashUndef)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#minus-hash-undef',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_optional_bind = new (require('@comunica/actor-rdf-join-optional-bind').ActorRdfJoinOptionalBind)({
  'bindOrder': 'depth-first',
  'selectivityModifier': 0.0001,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#optional-bind',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_rdf_join_actors_optional_nested_loop = new (require('@comunica/actor-rdf-join-optional-nestedloop').ActorRdfJoinOptionalNestedLoop)({
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#optional-nested-loop',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_query_operation_actors_update_clear = new (require('@comunica/actor-query-operation-update-clear').ActorQueryOperationClear)({
  'mediatorUpdateQuads': urn_comunica_default_rdf_update_quads_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-clear',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_update_delete_insert = new (require('@comunica/actor-query-operation-update-deleteinsert').ActorQueryOperationUpdateDeleteInsert)({
  'mediatorUpdateQuads': urn_comunica_default_rdf_update_quads_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-delete-insert',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_update_create = new (require('@comunica/actor-query-operation-update-create').ActorQueryOperationCreate)({
  'mediatorUpdateQuads': urn_comunica_default_rdf_update_quads_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-create',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_update_drop = new (require('@comunica/actor-query-operation-update-drop').ActorQueryOperationDrop)({
  'mediatorUpdateQuads': urn_comunica_default_rdf_update_quads_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-drop',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_update_load = new (require('@comunica/actor-query-operation-update-load').ActorQueryOperationLoad)({
  'mediatorUpdateQuads': urn_comunica_default_rdf_update_quads_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#update-load',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_join = new (require('@comunica/actor-query-operation-join').ActorQueryOperationJoin)({
  'mediatorJoin': urn_comunica_default_rdf_join_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#join',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_leftjoin = new (require('@comunica/actor-query-operation-leftjoin').ActorQueryOperationLeftJoin)({
  'mediatorJoin': urn_comunica_default_rdf_join_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#leftjoin',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_minus = new (require('@comunica/actor-query-operation-minus').ActorQueryOperationMinus)({
  'mediatorJoin': urn_comunica_default_rdf_join_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#minus',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_query_operation_actors_path_seq = new (require('@comunica/actor-query-operation-path-seq').ActorQueryOperationPathSeq)({
  'mediatorJoin': urn_comunica_default_rdf_join_mediators_main,
  'mediatorQueryOperation': urn_comunica_default_query_operation_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#path-seq',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_rdf_join_actors_inner_multi_smallest = new (require('@comunica/actor-rdf-join-inner-multi-smallest').ActorRdfJoinMultiSmallest)({
  'mediatorJoinEntriesSort': urn_comunica_default_rdf_join_entries_sort_mediators_main,
  'mediatorJoin': urn_comunica_default_rdf_join_mediators_main,
  'mediatorJoinSelectivity': urn_comunica_default_rdf_join_selectivity_mediators_main,
  'name': 'urn:comunica:default:rdf-join/actors#inner-multi-smallest',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_join__2_0_0_components_ActorRdfJoin_jsonld_ActorRdfJoin_default_bus
});
const urn_comunica_default_query_operation_actors_quadpattern = new (require('@comunica/actor-query-operation-quadpattern').ActorQueryOperationQuadpattern)({
  'mediatorResolveQuadPattern': urn_comunica_default_rdf_resolve_quad_pattern_mediators_main,
  'name': 'urn:comunica:default:query-operation/actors#quadpattern',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_query_operation__2_0_0_components_ActorQueryOperation_jsonld_ActorQueryOperation_default_bus
});
const urn_comunica_default_rdf_resolve_quad_pattern_actors_federated = new (require('@comunica/actor-rdf-resolve-quad-pattern-federated').ActorRdfResolveQuadPatternFederated)({
  'mediatorResolveQuadPattern': urn_comunica_default_rdf_resolve_quad_pattern_mediators_main,
  'skipEmptyPatterns': false,
  'name': 'urn:comunica:default:rdf-resolve-quad-pattern/actors#federated',
  'bus': https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_rdf_resolve_quad_pattern__2_0_0_components_ActorRdfResolveQuadPattern_jsonld_ActorRdfResolveQuadPattern_default_bus
});
const urn_comunica_default_Runner = (https___linkedsoftwaredependencies_org_bundles_npm__comunica_bus_init__2_0_0_components_ActorInit_jsonld_ActorInit_default_bus, [
  urn_comunica_default_context_preprocess_actors_source_to_destination,
  urn_comunica_default_hash_bindings_actors_sha1,
  urn_comunica_default_init_actors_query,
  urn_comunica_default_optimize_query_operation_actors_join_bgp,
  urn_comunica_default_optimize_query_operation_actors_bgp_to_join,
  urn_comunica_default_optimize_query_operation_actors_join_connected,
  urn_comunica_default_query_parse_actors_sparql,
  urn_comunica_default_query_parse_actors_graphql,
  urn_comunica_default_query_result_serialize_actors_json,
  urn_comunica_default_query_result_serialize_actors_rdf,
  urn_comunica_default_query_result_serialize_actors_simple,
  urn_comunica_default_query_result_serialize_actors_csv,
  urn_comunica_default_query_result_serialize_actors_sparql_json,
  urn_comunica_default_query_result_serialize_actors_sparql_tsv,
  urn_comunica_default_query_result_serialize_actors_sparql_xml,
  urn_comunica_default_query_result_serialize_actors_stats,
  urn_comunica_default_query_result_serialize_actors_table,
  urn_comunica_default_query_result_serialize_actors_tree,
  urn_comunica_default_rdf_join_entries_sort_actors_cardinality,
  urn_comunica_default_rdf_join_selectivity_actors_variable_counting,
  urn_comunica_default_rdf_serialize_actors_n3,
  urn_comunica_default_rdf_serialize_actors_jsonld,
  urn_comunica_default_rdf_update_quads_actors_rdfjs_store,
  urn_comunica_default_query_operation_actors_ask,
  urn_comunica_default_query_operation_actors_bgp,
  urn_comunica_default_query_operation_actors_construct,
  urn_comunica_default_query_operation_actors_describe,
  urn_comunica_default_query_operation_actors_distinct,
  urn_comunica_default_query_operation_actors_extend,
  urn_comunica_default_query_operation_actors_filter,
  urn_comunica_default_query_operation_actors_from,
  urn_comunica_default_query_operation_actors_group,
  urn_comunica_default_query_operation_actors_join,
  urn_comunica_default_query_operation_actors_leftjoin,
  urn_comunica_default_query_operation_actors_minus,
  urn_comunica_default_query_operation_actors_nop,
  urn_comunica_default_query_operation_actors_orderby,
  urn_comunica_default_query_operation_actors_project,
  urn_comunica_default_query_operation_actors_quadpattern,
  urn_comunica_default_query_operation_actors_reduced,
  urn_comunica_default_query_operation_actors_service,
  urn_comunica_default_query_operation_actors_slice,
  urn_comunica_default_query_operation_actors_sparql_endpoint,
  urn_comunica_default_query_operation_actors_union,
  urn_comunica_default_query_operation_actors_values,
  urn_comunica_default_query_operation_actors_path_alt,
  urn_comunica_default_query_operation_actors_path_inv,
  urn_comunica_default_query_operation_actors_path_link,
  urn_comunica_default_query_operation_actors_path_nps,
  urn_comunica_default_query_operation_actors_path_one_or_more,
  urn_comunica_default_query_operation_actors_path_seq,
  urn_comunica_default_query_operation_actors_path_zero_or_more,
  urn_comunica_default_query_operation_actors_path_zero_or_one,
  urn_comunica_default_query_operation_actors_update_add,
  urn_comunica_default_query_operation_actors_update_clear,
  urn_comunica_default_query_operation_actors_update_composite,
  urn_comunica_default_query_operation_actors_update_copy,
  urn_comunica_default_query_operation_actors_update_delete_insert,
  urn_comunica_default_query_operation_actors_update_create,
  urn_comunica_default_query_operation_actors_update_drop,
  urn_comunica_default_query_operation_actors_update_load,
  urn_comunica_default_query_operation_actors_update_move,
  urn_comunica_default_rdf_join_actors_inner_none,
  urn_comunica_default_rdf_join_actors_inner_single,
  urn_comunica_default_rdf_join_actors_inner_multi_empty,
  urn_comunica_default_rdf_join_actors_inner_multi_bind,
  urn_comunica_default_rdf_join_actors_inner_hash,
  urn_comunica_default_rdf_join_actors_inner_symmetric_hash,
  urn_comunica_default_rdf_join_actors_inner_nested_loop,
  urn_comunica_default_rdf_join_actors_inner_multi_smallest,
  urn_comunica_default_rdf_join_actors_minus_hash,
  urn_comunica_default_rdf_join_actors_minus_hash_undef,
  urn_comunica_default_rdf_join_actors_optional_bind,
  urn_comunica_default_rdf_join_actors_optional_nested_loop,
  urn_comunica_default_rdf_resolve_quad_pattern_actors_federated,
  urn_comunica_default_rdf_resolve_quad_pattern_actors_rdfjs_source
]);
module.exports = urn_comunica_default_init_actors_query;

